import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PythonConsole from "./components/python-console";
import Home from "./pages/home";
import LandingPage from "./pages/landing";

const RoutesCode = () => (
  <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/playground/" element={<PythonConsole />} />
      <Route path="/playground/:filename" element={<PythonConsole />} />
      <Route path="*" element={<Home message={"No encontrado"} />} />
    </Routes>
  </Router>
);

export default RoutesCode;
