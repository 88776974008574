// src/LandingPage.js
import React from "react";
import { useNavigate } from 'react-router-dom'

import { Button } from "antd-mobile";
import { Link } from "react-router-dom";
import logo from "../assets/logo.webp";

function LandingPage() {
  const navigate = useNavigate();

  return (
    <div className="container text-center my-5">
      <img src={logo} alt="LittleCoders Logo" style={{ width: "200px" }} />
      <h1 className="my-4">¡Bienvenido a LittleCoders!</h1>
      <p className="lead">
        El lugar perfecto para que los estudiantes comiencen su aventura en la
        programación.
      </p>

      <Button
        color="primary"
        size="large"
        className="mt-4"
        onClick={() => {
          navigate("/playground");
        }}
      >
        <i className="fas fa-code me-2"></i> ¡Empieza a Programar!
      </Button>
      <div className="mt-5">
        <h2>¿Por qué aprender con nosotros?</h2>
        <p>
          LittleCoders ofrece una plataforma interactiva para aprender Python de
          forma divertida y práctica.
        </p>
      </div>
    </div>
  );
}

export default LandingPage;
