// src/PythonConsole.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Toast } from 'antd-mobile';
import { Card, Spinner } from 'react-bootstrap';
import Editor from '@monaco-editor/react';

function PythonConsole() {
  const { filename } = useParams();
  const [pyodide, setPyodide] = useState(null);
  const [code, setCode] = useState('print("¡Hola, LittleCoder!")');
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPyodide = async () => {
      try {
        const pyodide = await window.loadPyodide({
          indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/',
        });
        setPyodide(pyodide);
      } catch (error) {
        console.error('Error al cargar Pyodide:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!window.loadPyodide) {
      const script = document.createElement('script');
      script.src = 'https://cdn.jsdelivr.net/pyodide/v0.23.4/full/pyodide.js';
      script.onload = loadPyodide;
      document.body.appendChild(script);
    } else {
      loadPyodide();
    }
  }, []);

  useEffect(() => {
    if (filename) {
      const fetchCode = async () => {
        try {
          //TODO: resolverlo mejor en un subdir, pero no funciona por permisos o CORS
          const response = await fetch(`/${filename}.json`);
          if (!response.ok) {
            throw new Error('No se pudo cargar el código');
          }
          const data = await response.json();
          setCode(data.code);
        } catch (error) {
          console.error('Error al cargar el código:', error);
          setCode('# Error al cargar el código. Prueba con tu propio codigo y aprende.');
        }
      };
      fetchCode();
    }
  }, [filename]);

  const runCode = async () => {
    if (!pyodide) return;

    try {
      setOutput('');
      Toast.show({
        icon: 'loading',
        content: 'Ejecutando código...',
        duration: 0,
      });

      await pyodide.runPythonAsync(`
import sys
from io import StringIO
sys.stdout = sys.stderr = StringIO()
      `);

      await pyodide.runPythonAsync(code);

      const stdout = await pyodide.runPythonAsync('sys.stdout.getvalue()');
      setOutput(stdout);
    } catch (err) {
      setOutput(err.toString());
    } finally {
      Toast.clear();
    }
  };

  return (
    <div className="container my-4">
      <Link to="/" className="text-white">
        <i className="fas fa-arrow-left me-2"></i> Volver a Inicio
      </Link>
      <Card className="mt-3 bg-dark text-white">
        <Card.Header>
          <h2 className="text-center">
            <i className="fab fa-python"></i> Consola Python
          </h2>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <div className="text-center my-5">
              <Spinner animation="border" variant="light" />
              <p>Cargando Pyodide...</p>
            </div>
          ) : (
            <>
              <Editor
                height="250px"
                defaultLanguage="python"
                value={code}
                onChange={(value) => setCode(value)}
                //theme="vs-dark"
              />
              <div className="text-center my-3">
                <Button color="primary" size="large" onClick={runCode}>
                  <i className="fas fa-play me-2"></i> Ejecutar
                </Button>
              </div>
              <h3>Salida:</h3>
              <pre className="bg-secondary p-3 text-white">{output}</pre>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default PythonConsole;
